import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WebsiteNavigationComponent } from '../../../../../shared/website_header/website-navigation/website-navigation.component';

@Component({
  selector: 'gitex-lead-web-main-website',
  standalone: true,
  imports: [WebsiteNavigationComponent, RouterModule],
  templateUrl: './main-website.component.html',
  styleUrl: './main-website.component.scss'
})
export class MainWebsiteComponent {

}
