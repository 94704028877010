import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { TeamMemberWeb } from '../models/teamMember.model';
import { CommonResponse } from '../models/common.model';

@Injectable({
  providedIn: 'root'
})
export class WebstieAuthService {

  showHeader: BehaviorSubject<boolean> =  new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient
  ) { }

  userLogin(email: string): Observable<TeamMemberWeb> {
    return this.http.post<{data: TeamMemberWeb}>(`${environment.base_url}user/login`, email).pipe(map(res => res.data));
  }

  getUserDetailWithId(id: string): Observable<TeamMemberWeb> {
    return this.http.get<{data: TeamMemberWeb}>(`${environment.base_url}user/getProfile/${id}`).pipe(map(res => res.data));
  }

  logout(id: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(`${environment.base_url}user/logout/${id}`);
  }
}
