import { Component, Inject, OnDestroy, PLATFORM_ID } from '@angular/core';
import { assets } from '../../../core/constants/assets.const';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { WebstieAuthService } from '../../../services/webstie-auth.service';
import { AuthService } from '../../../services/adminauth.service';
import { SpinnerService } from '../../../services/spinner.service';
import { CommonResponse } from '../../../models/common.model';
import { responseStatus } from '../../../core/constants/responseStatus.const';
import { environment } from '../../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'gitex-lead-web-website-navigation',
  standalone: true,
  imports: [],
  templateUrl: './website-navigation.component.html',
  styleUrl: './website-navigation.component.scss'
})
export class WebsiteNavigationComponent implements OnDestroy {
  protected assetsConst = assets;
  protected showHeader = false;
  subscriptionSubject$!: Subscription;
  protected userId: string | null = null;
  private destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private spinnerService: SpinnerService,
    protected websiteAuthService: WebstieAuthService,
    private _authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: object
  ) {

    this.subscriptionSubject$ = this.websiteAuthService.showHeader
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (res: boolean) => {
          this.showHeader = res;
        }
      });

    this.userId = this.route.snapshot.paramMap.get('id');
  }

  logout(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this._authService.logoutAlert().then((res: any) => {
      if (
        Object.prototype.hasOwnProperty.call(res, 'value') &&
        res.isConfirmed === true
      ) {
        this.spinnerService.addToLoader('logout');
        this.websiteAuthService
          .logout(this.userId ? this.userId : '')
          .pipe(takeUntil(this.destroy))
          .subscribe({
            next: (res: CommonResponse) => {
              if (res.statusCode === responseStatus.SUCCESS) {
                this.websiteAuthService.showHeader.next(false);
                if (isPlatformBrowser(this.platformId)) {
                  localStorage.removeItem(environment.storageKeyWeb);
                }
                this.spinnerService.removeFromLoader('logout');
                this.router.navigate(['/website']);
              }
            },
            error: () => {
              this.spinnerService.removeFromLoader('logout');
            }
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy.complete();
    this.destroy.unsubscribe();
  }
}
