@if (showHeader) {
  <header>
    <div class="nav_top">
      <figure class="mn_lgoo">
        <img [src]="assetsConst.logo" alt="Logo" />
      </figure>
      <div class="rt_nv">
        <ul class="top_mn">
          <li class="usr_img">
            <figure><img [src]="assetsConst.profile" alt="User" /></figure>
          </li>
          <li class="logut_img" (click)="logout()">
            <figure><img [src]="assetsConst.logout" alt="Image" /></figure>
          </li>
        </ul>
      </div>
    </div>
  </header>
}
